import React from 'react';

const ChemicalsSVGIcon = () => {
  return (
    <svg
      id='noun_chemical_2219042'
      xmlns='http://www.w3.org/2000/svg'
      width='99.294'
      height='148.94'
      viewBox='0 0 99.294 148.94'>
      <g id='Group_972' data-name='Group 972' transform='translate(0 0)'>
        <path
          id='Path_1300'
          data-name='Path 1300'
          d='M112.811,31.788h-7.447V26.823a2.482,2.482,0,0,0-2.482-2.482H97.917V4.482a2.482,2.482,0,0,0-4.965,0V24.341H87.988a2.482,2.482,0,0,0-2.482,2.482v4.965H65.647V21.4A7.43,7.43,0,0,0,63.164,6.965H48.27A7.43,7.43,0,0,0,45.788,21.4V31.788H25.929a2.482,2.482,0,1,0,0,4.965H45.788v16.49a32.27,32.27,0,1,0,19.859,0V36.753H85.505v4.965A2.482,2.482,0,0,0,87.988,44.2h4.965v91.846H75.576v-2.482a7.447,7.447,0,0,0-7.447-7.447V118.67H63.164v7.447H58.2v-4.965H53.235v4.965H48.27V118.67H43.306v7.447a7.447,7.447,0,0,0-7.447,7.447v2.482H23.447a7.447,7.447,0,1,0,0,14.894H100.4a7.447,7.447,0,1,0,0-14.894H97.917V44.2h4.965a2.482,2.482,0,0,0,2.482-2.482V36.753h7.447a2.482,2.482,0,0,0,0-4.965ZM48.27,11.929H63.164a2.482,2.482,0,1,1,0,4.965H48.27a2.482,2.482,0,1,1,0-4.965Zm2.482,9.929h9.929v9.929H50.753ZM62.544,57.51a27.306,27.306,0,1,1-13.653,0,2.482,2.482,0,0,0,1.862-2.4V36.753h9.929V55.107A2.482,2.482,0,0,0,62.544,57.51Zm-21.72,76.054a2.482,2.482,0,0,1,2.482-2.482H68.129a2.482,2.482,0,0,1,2.482,2.482v2.482H40.823Zm62.058,9.929a2.482,2.482,0,0,1-2.482,2.482H23.447a2.482,2.482,0,1,1,0-4.965H100.4A2.482,2.482,0,0,1,102.882,143.493ZM100.4,39.235H90.47V29.306H100.4Z'
          transform='translate(-16 -2)'
          fill='#a4c627'
        />
        <path
          id='Path_1301'
          data-name='Path 1301'
          d='M49.649,35.274C47.224,32.034,44.193,28,38.131,28a12.615,12.615,0,0,0-10.114,5.48,27.279,27.279,0,1,0,42.891-2.085,3.031,3.031,0,0,0-2.122-1.043,2.952,2.952,0,0,0-2.216.776,28.246,28.246,0,0,0-3.561,4.146c-2.331,3.1-3.795,4.85-6.7,4.85S51.974,38.372,49.649,35.274Zm18.21,3.637c.179-.242.361-.479.543-.715A21.217,21.217,0,1,1,29.063,49.217a20.95,20.95,0,0,1,3.777-12.066,6.386,6.386,0,0,1,5.292-3.089c2.889,0,4.346,1.752,6.668,4.85,2.425,3.24,5.456,7.274,11.518,7.274S65.416,42.155,67.859,38.912Z'
          transform='translate(-8.766 31.35)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3509'
          data-name='Rectangle 3509'
          width='5.93'
          height='6.919'
          transform='translate(27.397 77.832)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3510'
          data-name='Rectangle 3510'
          width='5.93'
          height='6.919'
          transform='translate(34.6 88.263)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3511'
          data-name='Rectangle 3511'
          width='6.919'
          height='5.93'
          transform='translate(44.113 83.557)'
          fill='#a4c627'
        />
      </g>
    </svg>
  );
};

export default ChemicalsSVGIcon;
