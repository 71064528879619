import React from 'react';

const OilSVGIcon = () => {
  return (
    <svg
      id='noun_oiler_3088151'
      xmlns='http://www.w3.org/2000/svg'
      width='148.939'
      height='148.939'
      viewBox='0 0 148.939 148.939'>
      <g id='Group_975' data-name='Group 975' transform='translate(0 0)'>
        <path
          id='Path_1312'
          data-name='Path 1312'
          d='M61.669,19.521l-4.218,2.3,1.766,3.241a4.788,4.788,0,0,1,.589,2.3v.516a4.809,4.809,0,0,1-4.8,4.8v4.8a9.619,9.619,0,0,0,9.609-9.609v-.516a9.636,9.636,0,0,0-1.172-4.6Z'
          transform='translate(74.721 24.971)'
          fill='#a4c627'
        />
        <path
          id='Path_1313'
          data-name='Path 1313'
          d='M83.089,32.912,71.878,12.361a6.446,6.446,0,0,0-11.319,0L49.347,32.912A19.284,19.284,0,0,0,47,42.115v.516a19.218,19.218,0,0,0,38.436,0v-.516A19.284,19.284,0,0,0,83.089,32.912Zm-2.457,9.719a14.413,14.413,0,0,1-28.827,0v-.516a14.471,14.471,0,0,1,1.758-6.9L64.774,14.66a1.646,1.646,0,0,1,2.888,0L78.873,35.211a14.472,14.472,0,0,1,1.758,6.9Z'
          transform='translate(63.503 10.218)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3527'
          data-name='Rectangle 3527'
          width='4.87'
          height='5.682'
          transform='translate(132.299 76.029)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3528'
          data-name='Rectangle 3528'
          width='4.87'
          height='4.87'
          transform='translate(132.299 86.581)'
          fill='#a4c627'
        />
        <path
          id='Path_1314'
          data-name='Path 1314'
          d='M142.733,109.1h-2.4a2.4,2.4,0,0,1-2.4-2.4V97.09h-4.8V106.7a7.216,7.216,0,0,0,7.207,7.207h2.4a2.4,2.4,0,1,1,0,4.8H125.917a7.207,7.207,0,1,0,0,14.413h9.609a2.4,2.4,0,1,1,0,4.8H101.894V113.905h19.218a7.207,7.207,0,1,0,0-14.413h-7.207a2.4,2.4,0,1,1,0-4.8h7.207a7.216,7.216,0,0,0,7.207-7.207V77.872h-4.8v9.609a2.4,2.4,0,0,1-2.4,2.4h-7.207a7.207,7.207,0,1,0,0,14.413h7.207a2.4,2.4,0,1,1,0,4.8H101.894V79.28L87.481,64.866V56.252a7.211,7.211,0,0,0-5.66-7.029l28.4-33.809h22.9V1H108.047a11.98,11.98,0,0,0-8.134,3.171L51.1,49.079a7.173,7.173,0,0,0-5.917,3.68L44.24,50.88A11.949,11.949,0,0,0,33.5,44.24H8.207a7.207,7.207,0,1,0,0,14.413H30.745l2.4,4.8H13.011A12.025,12.025,0,0,0,1,75.47v38.436a12.011,12.011,0,0,0,24.022,0V68.263H40.844L29.827,79.28V140.33c0,9.34,32.346,9.609,36.034,9.609,3.342,0,30.2-.221,35.224-7.207h34.441a7.207,7.207,0,1,0,0-14.413h-9.609a2.4,2.4,0,0,1,0-4.8h16.816a7.207,7.207,0,1,0,0-14.413Zm-122.515,4.8a7.207,7.207,0,0,1-14.413,0V75.47a7.216,7.216,0,0,1,7.207-7.207h7.207ZM47.637,68.263H84.084l9.609,9.609H38.028ZM97.09,82.676v9.609H34.631V82.676Zm0,14.413v33.631H34.631V97.09Zm6.075-89.383a7.188,7.188,0,0,1,4.881-1.9h20.273v4.8h-18.1a4.807,4.807,0,0,0-3.678,1.713L75.693,49.045H58.231ZM49.045,56.252a2.4,2.4,0,0,1,2.4-2.4H80.274a2.4,2.4,0,0,1,2.4,2.4v7.207H50.53l-1.485-2.969Zm-14,.255a4.773,4.773,0,0,0-4.3-2.657H8.207a2.4,2.4,0,1,1,0-4.8H33.5a7.173,7.173,0,0,1,6.445,3.983l4.3,8.6v1.835H38.518Zm30.818,88.628c-18.461,0-29.793-3.166-31.229-5.016v-4.593H97.09v4.593C95.653,141.968,84.322,145.135,65.861,145.135Z'
          transform='translate(-1 -1)'
          fill='#a4c627'
        />
        <path
          id='Path_1315'
          data-name='Path 1315'
          d='M33.609,67.958A9.607,9.607,0,0,0,41.3,52.586L33.609,42.333l-7.69,10.255a9.608,9.608,0,0,0,7.69,15.37ZM29.763,55.466l3.846-5.124,3.844,5.122a4.867,4.867,0,0,1,.961,2.885,4.8,4.8,0,0,1-9.609,0A4.869,4.869,0,0,1,29.763,55.466Z'
          transform='translate(31.252 56.959)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3529'
          data-name='Rectangle 3529'
          width='4.87'
          height='5.682'
          transform='translate(38.956 119.86)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3530'
          data-name='Rectangle 3530'
          width='4.87'
          height='4.058'
          transform='translate(38.956 101.191)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3531'
          data-name='Rectangle 3531'
          width='4.87'
          height='4.87'
          transform='translate(38.956 110.12)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3532'
          data-name='Rectangle 3532'
          width='5.682'
          height='4.87'
          transform='translate(86.033 110.12)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3533'
          data-name='Rectangle 3533'
          width='5.682'
          height='5.682'
          transform='translate(86.033 119.86)'
          fill='#a4c627'
        />
        <rect
          id='Rectangle_3534'
          data-name='Rectangle 3534'
          width='5.682'
          height='4.058'
          transform='translate(86.033 101.191)'
          fill='#a4c627'
        />
      </g>
    </svg>
  );
};

export default OilSVGIcon;
