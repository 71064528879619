import React from 'react';

const MetalSVGIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='150.089' height='149.94' viewBox='0 0 150.089 149.94'>
      <g id='noun_metal_profile_1283255' data-name='noun_metal profile_1283255' transform='translate(0.5 0.5)'>
        <g id='Group_974' data-name='Group 974' transform='translate(0)'>
          <path
            id='Path_1309'
            data-name='Path 1309'
            d='M117.9,57.638a1.908,1.908,0,0,0-2.085.6l-23.532,28.3H90.043V59.277a1.614,1.614,0,0,0-1.192-1.638,1.908,1.908,0,0,0-2.085.6L61.447,88.767A1.691,1.691,0,0,0,61,89.958V119a1.721,1.721,0,0,0,1.787,1.787H91.831a1.343,1.343,0,0,0,.745-.149.146.146,0,0,0,.149-.149c.149-.149.3-.149.447-.3h0L118.64,89.511a1.691,1.691,0,0,0,.447-1.192V59.277A1.742,1.742,0,0,0,117.9,57.638Zm-31.575,6.7V88.32a1.721,1.721,0,0,0,1.787,1.787h1.787v26.958H64.575V90.554Zm29.192,23.384L93.618,113.938V90.554l.6-.745a1.525,1.525,0,0,0,.745-.894l20.554-24.724V87.724Z'
            transform='translate(29.853 28.151)'
            fill='#a4c627'
            stroke='#a4c627'
            stroke-width='1'
          />
          <path
            id='Path_1310'
            data-name='Path 1310'
            d='M148.642.894a.146.146,0,0,0-.149-.149,1.487,1.487,0,0,0-.745-.6H147.6A.547.547,0,0,0,147.153,0H132.259a1.888,1.888,0,0,0-1.34.6L102.024,35.448H90.555V23.83L107.684,2.979a1.873,1.873,0,0,0,.3-1.936A2.271,2.271,0,0,0,106.194,0H91.6a1.888,1.888,0,0,0-1.34.6L67.321,28.447V2.83a1.614,1.614,0,0,0-1.192-1.638,1.908,1.908,0,0,0-2.085.6L29.49,44.235a1.691,1.691,0,0,0-.447,1.192V72.683H5.66L37.98,33.065a1.691,1.691,0,0,0,.447-1.192V2.83a1.614,1.614,0,0,0-1.192-1.638,1.908,1.908,0,0,0-2.085.6L.447,44.235A1.691,1.691,0,0,0,0,45.427V74.47a2.117,2.117,0,0,0,.3.894.146.146,0,0,0,.149.149,1.487,1.487,0,0,0,.745.6h0c.149,0,.447.149.6.149H27.852L.447,109.62c-.149.149-.149.149-.149.3s-.149.149-.149.3h0V147a1.721,1.721,0,0,0,1.787,1.787H56.448a1.342,1.342,0,0,0,.745-.149l.149-.149c.149-.149.3-.149.447-.3h0L148.642,39.171a1.691,1.691,0,0,0,.447-1.192V1.787Zm-15.49,2.681H143.28L55.406,109.024h-9.83ZM32.767,46.022,63.746,8.043V31.277L32.767,69.406Zm-29.192,0L34.7,8.043V31.277L3.575,69.406ZM92.641,3.575h9.83L15.49,109.024H5.66ZM3.575,112.6H16.383a1.888,1.888,0,0,0,1.34-.6L86.832,28.15v9.085a1.721,1.721,0,0,0,1.787,1.787H98.9l-58.533,70.6a1.873,1.873,0,0,0-.3,1.936A1.831,1.831,0,0,0,41.7,112.6h12.66v32.618H3.575ZM145.365,37.533,58.087,142.088V111.407L145.365,6.851Z'
            transform='translate(0)'
            fill='#a4c627'
            stroke='#a4c627'
            stroke-width='1'
          />
          <path
            id='Path_1311'
            data-name='Path 1311'
            d='M32.16,78c-7.149,0-12.66,4.021-12.66,9.085s5.66,9.085,12.66,9.085,12.66-4.021,12.66-9.085C44.969,82.021,39.309,78,32.16,78Zm0,14.6c-4.915,0-9.085-2.532-9.085-5.511s4.17-5.511,9.085-5.511,9.085,2.532,9.085,5.511S37.224,92.6,32.16,92.6Z'
            transform='translate(9.543 38.173)'
            fill='#a4c627'
            stroke='#a4c627'
            stroke-width='1'
          />
        </g>
      </g>
    </svg>
  );
};

export default MetalSVGIcon;
