import React from 'react';
import { Card, CardContent, Typography, Box, Grid, makeStyles } from '@material-ui/core';
import colors from '../../constants/colors';
import '../card-strip/card-strip.scss';
import Fade from 'react-reveal/Fade';
const useStyles = makeStyles((theme) => ({
  spacingColor: {
    position: 'relative',
    zIndex: 1,
    backgroundColor:
      theme.palette.type === 'dark'
        ? `${colors.primary.dark}${colors.transparency.button}`
        : `${colors.primary.light}${colors.transparency.button}`,
  },
  cornerCardBorder: {
    border: `5px solid ${theme.palette.background.default} !important`,
    borderRadius: '3px',
    backgroundColor: theme.palette.background.default,
    '&::before': {
      backgroundColor: theme.palette.background.default,
    },
    '&::after': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const FeaturedCardStatistic = ({ isData, data = {}, isSvg, svgData, subtitle }) => {
  const classes = useStyles();
  return (
    <Card elevation={0} className='card'>
      <Fade bottom>
        <Box className='borderGlow'></Box>
        <Box className={[classes.cornerCardBorder, 'cornerCardBorder'].join(' ')}>
          <CardContent className={` ${classes.spacingColor}`}>
            {isData ? (
              <Grid container direction='column'>
                <Grid item xs={12}>
                  <Typography variant='h4' color='primary' align='center'>
                    <Box fontWeight='fontWeightBold'>{data.value}</Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' color='primary' align='center'>
                    <Box fontWeight='fontWeightBold'>{data.text}</Box>
                  </Typography>
                </Grid>
              </Grid>
            ) : isSvg ? (
              <Grid container>
                <Box m='auto'>
                  <Grid item xs={12}>
                    {svgData}
                  </Grid>
                  {subtitle && (
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' color='primary' align='center' noWrap>
                        <Box fontWeight='fontWeightBold'>{subtitle}</Box>
                      </Typography>
                    </Grid>
                  )}
                </Box>
              </Grid>
            ) : null}
          </CardContent>
        </Box>
      </Fade>
    </Card>
  );
};

export default FeaturedCardStatistic;
