import React from 'react';

const MachinerySVGIcon = () => {
  return (
    <svg
      id='noun_machinery_2014014'
      xmlns='http://www.w3.org/2000/svg'
      width='141.724'
      height='148.94'
      viewBox='0 0 141.724 148.94'>
      <path
        id='Path_1305'
        data-name='Path 1305'
        d='M39.259,58.87c6.771,0,12.259-5.791,12.259-12.935S46.03,33,39.259,33,27,38.791,27,45.935,32.492,58.861,39.259,58.87Zm0-20.7a7.772,7.772,0,0,1,0,15.522,7.772,7.772,0,0,1,0-15.522Z'
        transform='translate(31.325 43.303)'
        fill='#a4c627'
      />
      <path
        id='Path_1306'
        data-name='Path 1306'
        d='M22.452,49.283A2.522,2.522,0,0,0,20,51.87V62.218A2.522,2.522,0,0,0,22.452,64.8h6.185a24.084,24.084,0,0,0,.888,2.258L25.15,71.678a2.688,2.688,0,0,0,0,3.658l6.934,7.318a2.362,2.362,0,0,0,3.467,0l4.375-4.613a21.662,21.662,0,0,0,2.141.935V85.5a2.522,2.522,0,0,0,2.452,2.587h9.808A2.522,2.522,0,0,0,56.778,85.5V78.975a21.664,21.664,0,0,0,2.141-.935l4.375,4.613a2.362,2.362,0,0,0,3.467,0l6.934-7.318a2.688,2.688,0,0,0,0-3.658L69.32,67.063a24.08,24.08,0,0,0,.889-2.259h6.185a2.521,2.521,0,0,0,2.452-2.587V51.87a2.521,2.521,0,0,0-2.452-2.587H70.209a24.085,24.085,0,0,0-.888-2.258L73.7,42.41a2.688,2.688,0,0,0,0-3.658l-6.934-7.318a2.362,2.362,0,0,0-3.467,0l-4.375,4.613a21.661,21.661,0,0,0-2.141-.935V28.587A2.521,2.521,0,0,0,54.327,26H44.519a2.522,2.522,0,0,0-2.452,2.587v6.526a21.664,21.664,0,0,0-2.14.935l-4.375-4.613a2.362,2.362,0,0,0-3.467,0L25.15,38.752a2.688,2.688,0,0,0,0,3.658l4.375,4.615a24.081,24.081,0,0,0-.888,2.258Zm11.821-4.565-3.922-4.137,3.467-3.659,3.922,4.137a2.348,2.348,0,0,0,2.981.4,16.571,16.571,0,0,1,4.411-1.925,2.566,2.566,0,0,0,1.839-2.5V31.174h4.9v5.852a2.566,2.566,0,0,0,1.839,2.5,16.571,16.571,0,0,1,4.41,1.925,2.356,2.356,0,0,0,2.981-.4l3.922-4.137,3.467,3.659-3.922,4.137a2.7,2.7,0,0,0-.378,3.144,18.567,18.567,0,0,1,1.829,4.656A2.477,2.477,0,0,0,68.4,54.457h5.546v5.174H68.4a2.478,2.478,0,0,0-2.373,1.939,18.568,18.568,0,0,1-1.829,4.656,2.7,2.7,0,0,0,.378,3.144l3.922,4.137-3.467,3.659-3.922-4.137a2.356,2.356,0,0,0-2.981-.4,16.571,16.571,0,0,1-4.411,1.925,2.566,2.566,0,0,0-1.839,2.5v5.852h-4.9V77.061a2.566,2.566,0,0,0-1.839-2.5,16.571,16.571,0,0,1-4.41-1.925,2.356,2.356,0,0,0-2.981.4l-3.922,4.137-3.467-3.659,3.922-4.137a2.7,2.7,0,0,0,.378-3.144,18.567,18.567,0,0,1-1.829-4.656,2.477,2.477,0,0,0-2.373-1.939H24.9V54.457h5.546a2.478,2.478,0,0,0,2.373-1.939,18.568,18.568,0,0,1,1.829-4.656A2.7,2.7,0,0,0,34.273,44.718Z'
        transform='translate(21.167 31.902)'
        fill='#a4c627'
      />
      <path
        id='Path_1307'
        data-name='Path 1307'
        d='M2,7.174H70.217v3.064a7.7,7.7,0,0,0-4.7,7.284,7.7,7.7,0,0,0,4.7,7.284V27.87H67.865a2.477,2.477,0,0,0-2.352,2.587v7.761H58.456A2.477,2.477,0,0,0,56.1,40.8v3.1L36.775,48.628a2.411,2.411,0,0,0-1.631,1.455L23.382,78.54a2.81,2.81,0,0,0,.147,2.44l11.762,20.7a2.257,2.257,0,0,0,2.643,1.116,2.564,2.564,0,0,0,1.7-2.487V80.029l4.25-14.021,15.051-7.094H86.058l15.212,9.56L105.5,80.107v20.2a2.564,2.564,0,0,0,1.7,2.487,2.257,2.257,0,0,0,2.643-1.116l11.762-20.7a2.81,2.81,0,0,0,.147-2.44L110,50.083a2.411,2.411,0,0,0-1.631-1.455L89.036,43.9V40.8a2.477,2.477,0,0,0-2.352-2.587H79.626V30.457a2.477,2.477,0,0,0-2.352-2.587H74.922V24.806a7.7,7.7,0,0,0,4.7-7.284,7.7,7.7,0,0,0-4.7-7.284V7.174h68.217V2H2ZM56.1,54.622,41.064,61.71a2.5,2.5,0,0,0-1.3,1.559l-4.7,15.522a2.813,2.813,0,0,0-.122.819V91.285L28.188,79.416,38.941,53.4,56.1,49.2ZM89.036,49.2,106.2,53.4l10.753,26.018-6.745,11.869V79.61a2.835,2.835,0,0,0-.168-.961l-4.7-12.935a2.528,2.528,0,0,0-1.018-1.285l-15.281-9.6ZM70.217,33.044h4.7v5.174h-4.7Zm7.057,10.348h7.057V53.74H60.808V43.392Zm-4.7-28.457a2.478,2.478,0,0,1,2.352,2.587,2.363,2.363,0,1,1-4.7,0A2.481,2.481,0,0,1,72.569,14.935Z'
        transform='translate(-1.999 -2)'
        fill='#a4c627'
      />
      <path
        id='Path_1308'
        data-name='Path 1308'
        d='M136.083,62.618V48.587A2.477,2.477,0,0,0,133.73,46H119.616a2.477,2.477,0,0,0-2.352,2.587V61.522H27.876V48.587A2.477,2.477,0,0,0,25.524,46H11.41a2.477,2.477,0,0,0-2.352,2.587V62.618c-5.1,2.447-7.947,8.5-6.809,14.491S8.194,87.4,13.762,87.392H131.378c5.568,0,10.375-4.288,11.513-10.283S141.187,65.065,136.083,62.618ZM121.969,51.174h9.409V61.522h-9.409Zm-108.207,0h9.409V61.522H13.762ZM131.378,82.218H13.762c-3.9,0-7.057-3.475-7.057-7.761S9.865,66.7,13.762,66.7H131.378c3.9,0,7.057,3.475,7.057,7.761S135.275,82.218,131.378,82.218Z'
        transform='translate(-1.999 61.548)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3514'
        data-name='Rectangle 3514'
        width='4.864'
        height='4.277'
        transform='translate(12.308 134.399)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3515'
        data-name='Rectangle 3515'
        width='4.864'
        height='4.277'
        transform='translate(25.775 134.399)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3516'
        data-name='Rectangle 3516'
        width='4.864'
        height='4.277'
        transform='translate(39.758 134.399)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3517'
        data-name='Rectangle 3517'
        width='4.864'
        height='4.277'
        transform='translate(53.741 134.399)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3518'
        data-name='Rectangle 3518'
        width='4.864'
        height='4.277'
        transform='translate(68.5 134.399)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3519'
        data-name='Rectangle 3519'
        width='4.864'
        height='4.277'
        transform='translate(81.706 134.399)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3520'
        data-name='Rectangle 3520'
        width='4.864'
        height='4.277'
        transform='translate(96.465 134.399)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3521'
        data-name='Rectangle 3521'
        width='4.864'
        height='4.277'
        transform='translate(110.448 134.399)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3522'
        data-name='Rectangle 3522'
        width='4.864'
        height='4.277'
        transform='translate(123.888 134.399)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3523'
        data-name='Rectangle 3523'
        width='60.804'
        height='5.132'
        transform='translate(0.147 10.658)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3524'
        data-name='Rectangle 3524'
        width='4.864'
        height='5.132'
        transform='translate(0.147 10.658)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3525'
        data-name='Rectangle 3525'
        width='5.675'
        height='5.132'
        transform='translate(136.049 10.658)'
        fill='#a4c627'
      />
      <rect
        id='Rectangle_3526'
        data-name='Rectangle 3526'
        width='62.426'
        height='5.132'
        transform='translate(79.298 10.658)'
        fill='#a4c627'
      />
    </svg>
  );
};

export default MachinerySVGIcon;
