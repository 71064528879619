import React from 'react';
import Layout from '../../../../components/layout';
import SectionContainerLayout from '../../../../components/section-container-layout/section-container-layout';
import SectionContainerLayoutWithFilter from '../../../../components/section-container-layout-with-filter';
import SectionDescription from '../../../../components/section-description-new';
import { Grid, Typography, Box } from '@material-ui/core';
import ImageContainer from '../../../../components/image-container';
import FeaturedCardStatistic from '../../../../components/featured-card-statistic';
import OilSVGIcon from '../../../../components/svgs/OilSVGIcon';
import MetalSVGIcon from '../../../../components/svgs/MetalSVGIcon';
import MachinerySVGIcon from '../../../../components/svgs/MachinerySVGIcon';
import ChemicalsSVGIcon from '../../../../components/svgs/ChemicalsSVGIcon';
import ForestryProductsSVGIcon from '../../../../components/svgs/ForestryProductsSVGIcon';
import FoodProductsSVGIcon from '../../../../components/svgs/FoodProductsSVGIcon';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'gatsby';

const OurHostRussia = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Layout>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <SectionContainerLayoutWithFilter
            title='YEKATERINBURG, RUSSIA'
            isViewAll
            viewAllText='Back To GMIS2019'
            baseLink='/summits/2019'>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant='body1' color='textSecondary'>
                  The second edition of the Global Manufacturing and Industrialisation Summit will be held in the city
                  of Yekaterinburg, the industrial heartland of Russia in July 2019, and will continue to intensify the
                  impact of innovation and Fourth Industrial Revolution technologies on the global manufacturing sector
                  through the worldwide dissemination of knowledge, best practices and standards.
                </Typography>
              </Grid>
            </Grid>
          </SectionContainerLayoutWithFilter>
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayout title='#ThisisRussia'>
            <SectionDescription
              left
              image
              filename='this-is-russia-2'
              text='Russia’s machine-building industry provides for most of the country’s needs, including steam boilers and turbines, electric generators, grain combines, automobiles, and electric locomotives, and lls much of its demand for shipbuilding, electric-power-generating and transmitting equipment, consumer durables, machine tools, instruments, and automation components. Russian factories also produce armaments – including tanks, jet ghters, androckets – which are sold to many countries and contribute signicantly to Russia’s export income. Older automobile factories are located in Moscow and Nizhny Novgorod, with the largest plants being
            those at Tolyatti (near Samara) and at Naberezhnye Chelny (in Tatarstan). Smaller producers of road
            vehicles are based in Miass, Ulyanovsk, and Izhevsk. Foreign trade is pivotal to the Russian economy'
            />
          </SectionContainerLayout>
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayout title="RUSSIA'S TRADE FACTS">
            <Typography variant='body1' gutterBottom color='textSecondary'>
              The country has generally enjoyed a healthy trade surplus since the dissolution of the Soviet Union and
              primary exports include oil, metals, machinery, chemicals, and forestry products, while principal imports
              include machinery and foods.
            </Typography>
            <Typography variant='body1' color='textSecondary'>
              Among Russia’s leading trade partners are Germany, the United States, Belarus, Ukraine, and China. The
              Russian manufacturing sector recorded strengthening optimism in 2017. According to the Russian Federal
              Statistics Service, in 2017, manufacturing contributed to RUB 10,635.8 billion of Russia’s total GDP – up
              from RUB 10.294.1 billion of the total GDP in 2016. Manufacturing was the fourth biggest contributor to
              the country’s GDP last year, after government, security and taxes; real estate; and trade and maintenance.
              Russian innovations have also been some of the most groundbreaking in modern history. with key
              manufacturing breakthroughs leading to solutions that have become an indispensable part of daily life,
              such as the rst electried tram line, the rst quality video- signal recorder, the modern radio, the
              creation of the worlds rst hydroplane, electronic television, transformers – an integral part of peer
              grids and synthetic rubber – used for everything from vehicle, aircraft, and bicycle tyres to seals,
              insulation, and medical devices.
            </Typography>
          </SectionContainerLayout>
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayout title='Primary Exports'>
            <Grid container wrap='nowrap' spacing={3} direction={isMobile && 'column'}>
              <Grid item xs={12} md={5}>
                <FeaturedCardStatistic isSvg svgData={<OilSVGIcon />} subtitle='OIL' />
              </Grid>
              <Grid item xs={12} md={5}>
                <FeaturedCardStatistic isSvg svgData={<MetalSVGIcon />} subtitle='METAL' />
              </Grid>
              <Grid item xs={12} md={5}>
                <FeaturedCardStatistic isSvg svgData={<MachinerySVGIcon />} subtitle='MACHINERY' />
              </Grid>
              <Grid item xs={12} md={5}>
                <FeaturedCardStatistic isSvg svgData={<ChemicalsSVGIcon />} subtitle='CHEMICALS' />
              </Grid>
              <Grid item xs={12} md={5}>
                <FeaturedCardStatistic isSvg svgData={<ForestryProductsSVGIcon />} subtitle='FORESTRY PRODUCTS' />
              </Grid>
            </Grid>
          </SectionContainerLayout>
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayout title='Primary Imports'>
            <Grid container wrap='nowrap' spacing={3} justify='center' direction={isMobile && 'column'}>
              <Grid item xs={12} md={5}>
                <FeaturedCardStatistic isSvg svgData={<MachinerySVGIcon />} subtitle='MACHINERY' />
              </Grid>
              <Grid item xs={12} md={5}>
                <FeaturedCardStatistic isSvg svgData={<FoodProductsSVGIcon />} subtitle='FOOD PRODUCTS' />
              </Grid>
            </Grid>
          </SectionContainerLayout>
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayout title='Leading Trade Partners'>
            <Grid container wrap='nowrap' spacing={3} direction={isMobile && 'column'}>
              <Grid item xs={12} md={5}>
                <ImageContainer filename='germany' altText='germany' />
                <Typography variant='h6' color='primary' align='center' nowrap>
                  <Box fontWeight='fontWeightBold'>Germany</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <ImageContainer filename='belarus' altText='belarus' />
                <Typography variant='h6' color='primary' align='center' nowrap>
                  <Box fontWeight='fontWeightBold'>Belarus</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <ImageContainer filename='ukraine' altText='ukraine' />
                <Typography variant='h6' color='primary' align='center' nowrap>
                  <Box fontWeight='fontWeightBold'>Ukraine</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <ImageContainer filename='china' altText='china' />
                <Typography variant='h6' color='primary' align='center' nowrap>
                  <Box fontWeight='fontWeightBold'>China</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <ImageContainer filename='usa' altText='usa' />
                <Typography variant='h6' color='primary' align='center' nowrap>
                  <Box fontWeight='fontWeightBold'>USA</Box>
                </Typography>
              </Grid>
            </Grid>
            <Box mt={8}>
              <SectionDescription
                image
                filename='yekaterinburg-1'
                text='Bounded by the 12 seas of the Atlantic, Arctic and Pacic Oceans, Russia’s 17.1 million sq km include one-eighth of the earth’s inhabited surface area. This makes it the world’s largest country, but Russia is about substance as well as size – oering international visitors a dynamic experience which encompasses unique geography and diverse cultures. Dynamism is a hallmark of the Russian city of Yekaterinburg, also known as Ekaterinburg; the country’s leading industrial hub and economic powerhouse. Located east of the Ural Mountains, at the crossroads of Europe and Asia, it is regarded as Russia’s premier city for business and investment. '
              />
            </Box>
          </SectionContainerLayout>
        </Grid>
        <Grid item xs={12}>
          <SectionContainerLayout title='DID YOU KNOW?'>
            <SectionDescription
              left
              image
              filename='yekateriburg-2'
              text='Often referred to as the unocial capital of the Urals – the region, where Russia’s largest metallurgical enterprises are concentrated - Yekaterinburg’s plants have, throughout history, been used to build some of the world’s greatest landmarks: You can learn about the city’s industrial past (and present) on one of many city tours; discover the story behind Yekaterinburg’s rst metallurgical plant, built in 1704; and see the legendary Uralmash for yourself. Russia’s third city – 1,036 miles (1,667km) east of the Russian capital – has plenty of culture to oer. It is known for the golden-domed Church on the Blood, built in the early 21st century on the site of the 1918 Romanov executions; the Monument to the Founders by the banks of the Iset River; and the nearby Sverdlovsk Regional Local Lore Museum, where exhibits include the Hall of the Romanovs, with personal items that belonged to Russia’s last royal family. '
            />
          </SectionContainerLayout>
        </Grid>
        {/* <Grid item xs={12}>
        *Timeline component*
          <SectionContainerLayout title='HISTORY OF YEKATERINBURG'></SectionContainerLayout>
        </Grid> */}
        <Grid item xs={12}>
          <SectionContainerLayout title='#GMIS2019 CO-HOSTS'>
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <Link to='https://minpromtorg.gov.ru/en/'>
                  <ImageContainer filename='minpromtorg' altText='minpromtorg' />
                </Link>
              </Grid>
              <Grid item xs={12} md={5}>
                <Link to='http://midural.ru'>
                  <ImageContainer filename='government-sverdlovsk-region' altText='government-sverdlovsk-region' />
                </Link>
              </Grid>
            </Grid>
          </SectionContainerLayout>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default OurHostRussia;
